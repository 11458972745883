import { Config } from 'configs';

export const getMonths = () => {
  let months = [
    {
      ID: 1,
      NAME: 'January',
    },
    {
      ID: 2,
      NAME: 'February',
    },
    {
      ID: 3,
      NAME: 'March',
    },
    {
      ID: 4,
      NAME: 'April',
    },
    {
      ID: 5,
      NAME: 'May',
    },
    {
      ID: 6,
      NAME: 'June',
    },
    {
      ID: 7,
      NAME: 'July',
    },
    {
      ID: 8,
      NAME: 'August',
    },
    {
      ID: 9,
      NAME: 'September',
    },
    {
      ID: 10,
      NAME: 'October',
    },
    {
      ID: 11,
      NAME: 'November',
    },
    {
      ID: 12,
      NAME: 'December',
    },
  ];

  return months;
};

export const getYears = () => {
  let years = [
    {
      ID: 2021,
      NAME: '2021',
    },
    {
      ID: 2022,
      NAME: '2022',
    },
    {
      ID: 2023,
      NAME: '2023',
    },
    {
      ID: 2024,
      NAME: '2024',
    },
    {
      ID: 2025,
      NAME: '2025',
    },
  ];

  return years;
};

export const getReportMode = () => {
  let view = [
    {
      ID: Config.REPORT_TYPE.SCANNERS,
      NAME: 'Show scanners',
    },
    {
      ID: Config.REPORT_TYPE.STORES,
      NAME: 'Show stores',
    },
  ];

  return view;
};
