/* eslint-disable radix */
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { CircularLoader } from 'components/Controls';
import { exportScans, getScans } from 'api';
import Paper from '@material-ui/core/Paper';
import { useForm } from 'react-hook-form';
import { buildQueryString } from 'utils/queryStringUtils';
import queryString from 'query-string';
import { Filter } from 'views/controls/scansFilter';
import { Context as AuthContext } from 'contexts/AuthContext';
import { Config } from 'configs';
import { useClient } from 'hooks/useClient';

export const StatisticsScansDataScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperBold: {
      padding: theme.spacing(1),
      fontSize: 14,
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    row: {
      height: 40,
      width: '100%',
      textAlign: 'center',
    },
    allScansTotalCellEmpty: {
      height: 60,
      width: '100%',
      textAlign: 'end',
      backgroundColor: '#FFFFFF',
    },
    allScansTotalCell: {
      height: 60,
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#e0ffee',
    },
    totals: {
      height: 40,
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#e0ffee',
    },
    totalsFirstRow: {
      height: 60,
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#e0ffee',
    },
    rowWithValue: {
      backgroundColor: '#F8F8F8',
    },
    tool: {
      height: 40,
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center',
    },
    storeNameHeader: {
      backgroundColor: 'lightgray',
      padding: theme.spacing(1),
    },
    storeName: {
      height: 40,
      width: '100%',
      textAlign: 'left',
      paddingLeft: 5,
      justifyContent: 'center',
    },
    storeNameLink: {
      textDecoration: 'none',
    },
    labelAction: {
      color: theme.palette.text.primary,
    },
  }));
  const classes = useStyles();
  const [canSeeSection, isClient, getClient, getClientGroup] = useClient();

  const currentMonth = new Date().getMonth() + 1;

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState(2025);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedReportType, setSelectedReportType] = useState();

  const [selectedClient, setSelectedClient] = useState(null);

  const { state: authState } = useContext(AuthContext);

  // filtering data
  const { register, handleSubmit, control, getValues } = useForm();

  // history
  const history = useHistory();

  const [isExporting, setIsExporting] = useState(false);

  const fetch = useCallback(async () => {
    setIsLoading(true);

    const qs = queryString.parse(location.search);

    let { client: requestedClient, month, year, reportMode, item } = qs;

    // overriding client from query string.

    let client = requestedClient;

    if (canSeeSection(requestedClient)) {
      setSelectedClient(client);
    }

    if (month) {
      setSelectedMonth(month);
    }
    if (year) {
      setSelectedYear(year);
    }

    let reportType = reportMode || Config.REPORT_TYPE.SCANNERS;

    setSelectedReportType(reportType);

    if (client) {
      setSelectedClient(client);
      const scansResult = await getScans(client, year, month, reportType, item);

      if (scansResult) {
        setResponse(scansResult);
        // prepareCsvData();
      }
    } else {
      setResponse(null);
      setIsLoading(false);
    }

    setIsLoading(false);
  }, [location.search, authState.user]);

  // const fetchStoreAndScannerPairs = useCallback(async () => {
  //   const pairs = await getStoreAndScannerPairs(authState.user?.id);
  //   if (pairs) {
  //     setScannerPairs(pairs);
  //   }
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    fetch();
  }, [fetch]);

  const onFilterClicked = (filterFormData) => {
    const query = buildQueryString(filterFormData);

    console.log('filterFormData', filterFormData);

    history.push({
      pathname: '/statistics/scans/data',
      search: `?${query}`,
    });
  };

  const onFilterReset = () => {
    history.push({
      pathname: '/statistics/scans/data',
      search: ``,
    });
    setResponse(null);
  };

  const getDaysInMonth = (yearFromFilter, monthFromFilter) => {
    const currentDate = new Date();
    let currentYear = yearFromFilter
      ? parseInt(yearFromFilter)
      : currentDate.getFullYear();
    let currentMonth = currentDate.getMonth() + 1;
    return new Date(currentYear, monthFromFilter || currentMonth, 0).getDate();
  };

  const prepareDataToRenderMonthDays = (days, itemScansOnDay) => {
    if (!itemScansOnDay) {
      return null;
    }

    let daysRendered = Array.from(Array(days).keys()).map((day) => {
      const dayNumber = day + 1;

      let itemScansOnThatDay =
        itemScansOnDay.days.find((x) => x.day === dayNumber)?.total || 0;

      let cellClass =
        itemScansOnThatDay && itemScansOnThatDay > 0
          ? classes.rowWithValue
          : classes.row;

      return (
        <div key={`day-${dayNumber}`}>
          <Paper className={cellClass}>
            <span>{itemScansOnThatDay}</span>
          </Paper>
        </div>
      );
    });

    // Add total scans cell at the beginning of the row
    daysRendered.unshift(
      <div key="total-scans">
        <Paper className={classes.totals}>
          <span style={{ height: '40px' }}>
            {itemScansOnDay.total_item_scans}
          </span>
        </Paper>
      </div>,
    );

    daysRendered.unshift(
      <div key="item-id">
        <Paper className={classes.tool}>
          {itemScansOnDay.item_id || 'N/A'}
        </Paper>
      </div>,
    );
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `150px repeat(${days + 2}, 65px)`,
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '40px',
          verticalAlign: 'middle',
        }}
      >
        {daysRendered}
      </div>
    );
  };

  const renderHeader = () => {
    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    let daysRendered = Array.from(Array(daysInMonth).keys()).map((day) => {
      const dayNumber = day + 1;
      let cellClass = classes.header;

      return (
        <div>
          <Paper className={cellClass}>
            <span>{dayNumber}</span>
          </Paper>
        </div>
      );
    });

    daysRendered.unshift(
      <div>
        <Paper className={classes.header}>
          <span>&nbsp;</span>
        </Paper>
      </div>,
    );

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '215px repeat(33, 65px)',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '40px',
          verticalAlign: 'middle',
        }}
      >
        {daysRendered}
      </div>
    );
  };

  const renderHeaderTotals = (daysInMonth, result) => {
    let daysRendered = Array(daysInMonth)
      .fill(0)
      .map((_, index) => {
        const { total_scans: scans, total_items: scannerOrStore } =
          result.totals.find((x) => x.day === index + 1) || {
            total_scans: 0,
            total_items: 0,
          };

        return (
          <div key={`day-${index}`}>
            <Paper className={classes.totalsFirstRow}>
              <span
                style={{
                  height: '40px',
                }}
              >
                <div>{(scans || 0).toLocaleString('en-US')}</div>
                <div style={{ height: '10px' }}>
                  <span
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    {scannerOrStore === 0 ? '-' : scannerOrStore}{' '}
                    {Config.REPORT_TYPE.SCANNERS === selectedReportType
                      ? 'tl.'
                      : 'st.'}
                  </span>
                </div>
              </span>
            </Paper>
          </div>
        );
      });

    daysRendered.unshift(
      <div>
        <Paper className={classes.allScansTotalCell}>
          <span
            style={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <div>
              {result?.total_monthly_scans?.toLocaleString('en-US') || 0} Scans.{' '}
            </div>
            <div>
              {result?.total_monthly_items?.toLocaleString('en-US') || 0}
              {Config.REPORT_TYPE.SCANNERS === selectedReportType
                ? 'Scanners (t.)'
                : ' Stores (st.)'}{' '}
              &#8681;
            </div>
          </span>
        </Paper>
      </div>,
    );

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '215px repeat(33, 65px)',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '30px',
          verticalAlign: 'middle',
        }}
      >
        {daysRendered}
      </div>
    );
  };

  const renderData = (daysInMonth, result, forExport) => {
    let rows = [];
    if (result && result.most_used_items && result.most_used_items.length > 0) {
      result.most_used_items.forEach((item) => {
        // const row = forExport
        //   ? prepareDataToRenderMonthDaysForExport(renderRequest)
        //   : prepareDataToRenderMonthDays(daysInMonth, item);

        const row = prepareDataToRenderMonthDays(daysInMonth, item);

        rows.push(row);
      });
    }

    // Add totals row
    // if (!forExport) {
    //   const totalsRow = constructTotalScansRow(totals);
    //   rows.unshift(totalsRow);
    // }

    return rows.map((r) => r);
  };

  const renderGrid = (response) => {
    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    return (
      <div>
        {renderHeader(daysInMonth)}
        {renderHeaderTotals(daysInMonth, response)}
        {renderData(daysInMonth, response)}
      </div>
    );
  };

  const canExport = () => {
    const formValues = getValues();
    const params = new URLSearchParams(location.search);
    const itemParam = params.get('item');

    return (
      selectedClient &&
      selectedYear &&
      selectedMonth &&
      !formValues.item && // Form filter
      !itemParam // URL parameter filter
    );
  };

  const handleExport = async () => {
    if (!canExport()) return;

    setIsExporting(true);
    try {
      await exportScans(
        selectedClient,
        selectedYear,
        selectedMonth,
        selectedReportType,
        getValues('item'),
      );
    } finally {
      setIsExporting(false);
    }
  };

  const fullWidthGrid = () => {
    if (!response) {
      return <div>No data</div>;
    }

    if (response.total_scans === 0) {
      return <div>No data</div>;
    }

    return (
      <div>
        {canExport() && (
          <Grid className={classes.filterItem} item>
            <Link
              component="button"
              variant="body2"
              onClick={handleExport}
              disabled={isExporting}
              style={{
                textDecoration: 'underline',
                cursor: isExporting ? 'default' : 'pointer',
                color: isExporting ? 'grey' : 'inherit',
              }}
            >
              {isExporting
                ? 'Exporting...'
                : `Export ${selectedMonth}/${selectedYear} data to CSV`}
            </Link>
          </Grid>
        )}
        <Grid container spacing={0}>
          {renderGrid(response)}
        </Grid>
      </div>
    );
  };

  // rendering
  return (
    <div className={classes.root}>
      <div>
        <Typography className={classes.pageTitle} align="left">
          Scan Sessions
        </Typography>
      </div>
      <Grid container direction="column">
        <Grid className={classes.filterItem} item>
          <Filter
            client={selectedClient}
            clientGroup={getClientGroup()}
            filter={onFilterClicked}
            reset={onFilterReset}
            register={register}
            handleSubmit={handleSubmit}
            location={location}
            control={control}
            defaultMonth={selectedMonth}
            defaultYear={selectedYear}
            getValues={getValues}
            reportType={selectedReportType}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>

      <div style={{ marginTop: 30 }}></div>
      {!isLoading && fullWidthGrid()}
      {isLoading && <CircularLoader />}
    </div>
  );
};
