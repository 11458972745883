export const Config = {
  SHOW_LOGS: true,
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || 'development',
  DISPLAY_MODE: {
    MONTHLY: 'monthly',
    DAILY: 'daily',
    CHART: 'chart',
  },
  REPORT_TYPE: {
    SCANNERS: 'scanners',
    STORES: 'stores',
    SCANNERS_AND_STORES: 'scanners-and-stores',
  },
  CLIENTS: {
    SEPHORA_NA: 'Sephora NA',
    SEPHORA_SEA: 'Sephora SEA',
    SEPHORA_EMEA: 'Sephora EMEA',
    WBA: 'WBA',
    JPMS: 'JPMS',
    SUPERPHARM: 'Superpharm',
    BLUSHBAR: 'Blushbar',
    ULTA: 'Ulta',
  },
  AUTH: {
    ADMIN: {
      ID: -1,
      NAME: 'admin@fitskin.co',
    },
    SEPHORA_NA: {
      ID: 'sephora_na',
      NAME: 'Sephora NA',
    },
    SEPHORA_SEA: {
      ID: 'sephora_sea',
      NAME: 'Sephora SEA',
    },
    SEPHORA_EMEA: {
      ID: 'sephora_emea',
      NAME: 'Sephora EMEA',
    },
    SEPHORA_CHINA: {
      ID: 'sephora_china',
      NAME: 'Sephora CN',
    },
    SEPHORA_ME: {
      ID: 'sephora_me',
      NAME: 'Sephora ME',
    },
    WBA: {
      ID: 'wba',
      NAME: 'WBA',
    },
    WBA_US: {
      ID: 'wba_us',
      NAME: 'WBA US',
    },
    WBA_NORDIC: {
      ID: 'wba_nordic',
      NAME: 'WBA Nordic',
    },
    WBA_ME: {
      ID: 'wba_me',
      NAME: 'WBA ME',
    },
    ATOMICA: {
      ID: 'skin-care',
      NAME: 'Atomica',
    },
    JUICE: {
      ID: 'juice',
      NAME: 'Juice',
    },
    BLUSHBAR: {
      ID: 'blushbar',
      NAME: 'Blushbar',
    },
    JPMS: {
      ID: 'jpms',
      NAME: 'JPMS',
    },
    SUPERPHARM: {
      ID: 'superpharm',
      NAME: 'Superpharm',
    },
    QAT: {
      ID: 'qat',
      NAME: 'Synergy',
    },
    ULTA: {
      ID: 'ulta',
      NAME: 'Ulta',
    },
  },
  ROLES: {
    AUTHENTICATED: 'AUTHENTICATED',
    UNAUTHENTICATED: 'UNAUTHENTICATED',
    ADMIN: 'sysadmin',
    CUSTOMER: 'customer',
  },
  DEFAULT_PAGE_SIZE: 20,
  DEFAULT_TAXONOMY_PAGE_SIZE: 1000,
  IMAGE_BASE_PATH:
    'https://fitskin-storage-sephora-paris1-copy.s3.eu-west-3.amazonaws.com',
  RULES_ID: '5ffee4734f2bcdc2b6bea6b0',
  IMAGES: {
    THUMBNAIL_SIZE: '150x150',
    PREVIEW_SIZE: process.env.REACT_APP_IMAGE_PREVIEW_SIZE || '700x700',
    XPOL_PREVIEW_SIZE:
      process.env.REACT_APP_XPOL_IMAGE_PREVIEW_SIZE || '700x700',
    NO_IMAGE: {
      DIR: 'system/db/images',
      IMAGE_NAME: '427724e3-7cdd-4be4-93cf-b3efc2edcec6.png',
    },
  },
  // Some conversion to bool trickery from https://stackoverflow.com/a/16313488
  SHOW_SELFIES: !!+process.env.REACT_APP_SHOW_SELFIES,
  SHOW_XPOL_AVERAGE: process.env.REACT_APP_SHOW_XPOL_AVERAGE || false,
  SHOW_DE_MAX: process.env.REACT_APP_SHOW_DE_MAX || false,
  SHOW_RELATIVE_SCORES_RATIO:
    process.env.REACT_APP_SHOW_RELATIVE_SCORES_RATIO || false,
  SHOW_SHADES_ACCURACY_RATIO:
    process.env.REACT_APP_ENABLE_SHADES_ACCURACCY_RATIO_CALCULATION || false,
  SHOW_RECOMMENDED_PRODUCTS:
    process.env.REACT_APP_SHOW_RECOMMENDED_PRODUCTS || false,
  SHOW_ANOMALY_FILTERS: !!+process.env.REACT_APP_FILTER_ANOMALIES,
  SHOW_ANOMALY_UV_FILTERS: !!+process.env.REACT_APP_FILTER_ANOMALIES_UV,
  SHOW_FINE_AND_COARSE_LINES_IN_LINES_ANALYSIS:
    process.env.REACT_APP_SHOW_FINE_AND_COARSE_LINES || false,
  SHOW_METADATA_LINK: process.env.REACT_APP_SHOW_METADATA_LINK || true,
  ENABLE_FILTER_BY_STORE_ID:
    process.env.REACT_APP_ENABLE_FILTER_BY_STORE_ID || false,
  MIGRATION_CLIENT_NAME:
    process.env.REACT_APP_MIGRATION_V1_V2_FILE_ROOT_NAME || 'undefined',
  AWS: {
    S3: {
      USE_FETCHING_USING_HTTP:
        process.env.REACT_APP_USE_FETCHING_USING_HTTP || true,
      USE_FETCHING_USING_S3_SDK:
        process.env.REACT_APP_USE_FETCHING_USING_S3_SDK || false,
      REACT_APP_USE_FETCHING_USING_S3_AP:
        process.env.REACT_APP_USE_FETCHING_USING_S3_AP || false,
      THUMBNAILS_BUCKET_URL: process.env.REACT_APP_AWS_S3_THUMBNAILS_BUCKET_URL,
      USE_AUTO_PREFIX:
        process.env.REACT_APP_AWS_S3_AUTOPREFIX_BY_SOURCE_TYPE || false,
      CREDENTIALS: {
        REGION: process.env.REACT_APP_AWS_REGION || 'eu-west-2',
        ACCESS_KEY_ID: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID || '',
        SECRET_ACCESS_KEY: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY || '',
        BUCKET_NAME: process.env.REACT_APP_AWS_S3_BUCKET_NAME || '',
        S3_BUCKET_PREFIX: process.env.REACT_APP_AWS_S3_BUCKET_PREFIX || '',
        REACT_APP_AWS_S3_ACCESS_POINT_ARN:
          process.env.REACT_APP_AWS_S3_ACCESS_POINT_ARN || '',
      },
    },
  },
};
