import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from 'components/Controls';
import {
  getDistinctAppVersions,
  getDistinctScanners,
  getScannersUsageByAppVersion,
} from 'api';
import { Form } from 'components/Controls';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import { clientsList, getClients } from 'views/controls/clients';
import { useClient } from 'hooks/useClient';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%', // Ensures the container takes full height if needed
  },
  pageTitle: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    fontSize: 24,
    fontWeight: 'bold',
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'column', // Arrange filters in a single column
    alignItems: 'flex-start',
    gap: theme.spacing(2), // Space between filter items
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  filterItem: {
    width: '25%', // Each filter takes up 25% of the screen width
    minWidth: '200px', // Ensures a minimum width for smaller screens
    padding: theme.spacing(1),
    boxSizing: 'border-box',
  },
  selectInput: {
    width: '100%', // Make Select input take full width of its container
  },
  paper: {
    width: '110px',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperBold: {
    width: '110px',
    padding: theme.spacing(1),
    fontSize: 14,
    backgroundColor: '#e0ffee',
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  csvLink: {
    marginTop: theme.spacing(2),
  },
  labelAction: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}));

export const StatisticsAppVersionsScreen = ({ location }) => {
  const classes = useStyles();

  const [_, isClient, getClient, getClientGroup] = useClient();
  const [customers] = useState(clientsList(getClientGroup()) || []);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedAppVersion, setSelectedAppVersion] = useState(null);
  const [scanners, setScanners] = useState([]);
  const [appVersions, setAppVersions] = useState([]);
  const [csvResultData, setCsvResultData] = useState(null);
  const [appVersionUsageResponse, setAppVersionUsageResponse] = useState(null);

  // Fetch app versions when selectedCustomer changes
  useEffect(() => {
    const fetchAppVersions = async () => {
      if (selectedCustomer) {
        setIsLoading(true);
        setCsvResultData(null);
        setAppVersions([]);
        setSelectedAppVersion(null);
        setScanners([]);
        setAppVersionUsageResponse(null);

        try {
          const appVersionsResult = await getDistinctAppVersions(
            selectedCustomer.value,
          );

          if (Array.isArray(appVersionsResult)) {
            const convertedAppVersions = appVersionsResult.map((item) => ({
              value: item,
              label: item,
            }));

            setAppVersions(convertedAppVersions);
          } else {
            console.error(
              'Invalid appVersionsResult format:',
              appVersionsResult,
            );
          }
        } catch (error) {
          console.error('Error fetching app versions:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        // If no customer is selected, reset states
        setAppVersions([]);
        setSelectedAppVersion(null);
        setScanners([]);
        setAppVersionUsageResponse(null);
        setCsvResultData(null);
      }
    };

    fetchAppVersions();
  }, [selectedCustomer]);

  // Fetch scanners when selectedAppVersion changes
  useEffect(() => {
    const fetchScanners = async () => {
      if (selectedCustomer && selectedAppVersion) {
        setIsLoading(true);
        setCsvResultData(null);
        setScanners([]);
        setAppVersionUsageResponse(null);

        try {
          const usageResult = await getScannersUsageByAppVersion(
            selectedCustomer.value,
            selectedAppVersion.value,
          );

          if (usageResult?.length === 1) {
            const usedScanners = usageResult[0].scanners;
            setAppVersionUsageResponse(usedScanners);

            // Fetch all scanners associated with the selected client
            const scannersResult = await getDistinctScanners(
              selectedCustomer.value,
            );

            // Ensure scannersResult is an array
            if (Array.isArray(scannersResult)) {
              setScanners(scannersResult);

              const unusedScanners = scannersResult.filter(
                (scanner) => !usedScanners.includes(scanner),
              );

              setCsvResultData(unusedScanners.map((item) => [item]));
            } else {
              console.error('Invalid scannersResult format:', scannersResult);
            }
          } else {
            // Handle cases where usageResult is not as expected
            setAppVersionUsageResponse([]);
            setCsvResultData(null);
          }
        } catch (error) {
          console.error('Error fetching scanners:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        // If no app version is selected, reset relevant states
        setScanners([]);
        setAppVersionUsageResponse(null);
        setCsvResultData(null);
      }
    };

    fetchScanners();
  }, [selectedAppVersion, selectedCustomer]);

  const renderAppVersionsGrid = (allScanners, usedScanners) => {
    const scannerIsUsed = (scanner) => usedScanners?.includes(scanner);

    if (allScanners && allScanners.length > 0) {
      return allScanners.map((cell, index) => {
        return (
          <Grid item key={cell}>
            <Paper
              className={
                scannerIsUsed(cell) ? classes.paperBold : classes.paper
              }
            >
              {cell}
            </Paper>
          </Grid>
        );
      });
    }
    return null;
  };

  return (
    <div className={classes.root}>
      {/* Page Title */}
      <Typography className={classes.pageTitle} align="left">
        App Versions Usage
      </Typography>

      {/* Filters Section */}
      <div className={classes.root}>
        <Form>
          <div className={classes.filtersContainer}>
            {/* Filter 1: Select Client */}
            <div className={classes.filterItem}>
              <Select
                onChange={(selectedItem) => {
                  setSelectedCustomer(selectedItem);
                }}
                placeholder="Select client"
                className={classes.selectInput}
                options={customers}
                value={selectedCustomer}
                isDisabled={isLoading}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    minHeight: '40px', // Ensures consistent height
                  }),
                }}
              />
            </div>

            {/* Filter 2: Select App Version */}
            <div className={classes.filterItem}>
              <Select
                onChange={(selectedItem) => {
                  setSelectedAppVersion(selectedItem);
                }}
                placeholder="Choose app version"
                isDisabled={
                  isLoading || !selectedCustomer || appVersions.length === 0
                }
                value={selectedAppVersion}
                options={appVersions}
                className={classes.selectInput}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    minHeight: '40px', // Ensures consistent height
                  }),
                }}
              />
            </div>
          </div>

          {/* CSV Export Link */}
          <Grid
            container
            className={classes.csvLink}
            justifyContent="flex-start"
          >
            {csvResultData && !isLoading && (
              <CSVLink
                filename={`${selectedAppVersion?.label}.csv`}
                data={csvResultData}
                style={{ textDecoration: 'none' }}
              >
                <Typography className={classes.labelAction} align="left">
                  Export unused scanners for {selectedAppVersion?.label} version
                  to CSV
                </Typography>
              </CSVLink>
            )}
          </Grid>
        </Form>
      </div>

      {/* Scanners Grid */}
      <div style={{ marginTop: 30 }}>
        {!isLoading && scanners.length > 0 && (
          <Grid container spacing={0}>
            <Grid container spacing={0}>
              {renderAppVersionsGrid(scanners, appVersionUsageResponse)}
            </Grid>
          </Grid>
        )}
      </div>

      {/* Loading Indicator */}
      {isLoading && <CircularLoader />}
    </div>
  );
};
