import { Context as AuthContext } from 'contexts/AuthContext';
import { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Config } from 'configs';
import Select from 'react-select';
import { clientsList, getClients } from './clients';
import { useClient } from 'hooks/useClient';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
  },
  filterItem: {
    width: '100%', // Ensures the container adapts properly
    display: 'flex', // Adds flexibility for child items
    justifyContent: 'flex-start', // Aligns items to the start
  },
}));

export const FilterByClient = ({ onClientChanged }) => {
  const { state: authState } = useContext(AuthContext);
  const [selectedClient, setSelectedClient] = useState(null);

  const classes = useStyles();
  const [_, isClient, getClient, getClientGroup] = useClient();
  const [isLoading, setIsLoading] = useState(false);

  const handleClientChange = (client) => {
    setSelectedClient(client);
    onClientChanged(client);
  };

  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '25vw', // 25% of the viewport width
      minWidth: '150px', // Optional minimum width
    }),
  };

  return (
    <div className={classes.root}>
      {authState.user?.role === Config.ROLES.ADMIN && (
        <Grid container direction="column">
          <Grid className={classes.filterItem} item>
            <div>
              <Grid container direction="column" item xs={12}>
                <Grid container item xs="auto" alignItems="center">
                  <Select
                    onChange={handleClientChange}
                    placeholder="Select customer"
                    value={selectedClient}
                    isDisabled={isLoading}
                    styles={customStyles} // Apply custom styles
                    options={clientsList(getClientGroup()) || []}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
      {authState.user?.role === Config.ROLES.CUSTOMER && (
        <Grid container direction="column">
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Typography className={classes.categoryTitle} align="left">
              {selectedClient?.label}
            </Typography>
          </div>
          {/* <Grid className={classes.filterItem} item>
            {selectedClient?.label}
          </Grid> */}
        </Grid>
      )}
    </div>
  );
};
