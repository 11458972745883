import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Link as MuiLink,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { history } from 'utils/history';
import { getDailyAnomalies, getDailyUvDefectiveAnomalies } from 'api';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { FilterByClient } from 'views/controls/filterByClient';
import { useClient } from 'hooks/useClient';

export const AnomaliesDailyReviewScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      //marginLeft: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperLeft: {
      padding: theme.spacing(1),
      textAlign: 'left',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    paperRight: {
      padding: theme.spacing(1),
      textAlign: 'right',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
      // textDecoration: 'underline',
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    sectionTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 20,
      marginTop: 20,
      fontWeight: 'bold',
    },
    warning: {
      color: 'red',
      padding: theme.spacing(1),
      fontSize: 16,
      fontWeight: 'bold',
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));
  const classes = useStyles();
  const [jsonResponse, setJsonResponse] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [loadingRow, setLoadingRow] = useState({}); // Track loading state by row
  const [canSeeSection] = useClient();

  const handleClientChanged = (client) => {
    console.log('Client changed:', client);
    setSelectedClient(client);
  };

  const fetchChart = useCallback(async (chartId, placementId) => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-warehouse-uhqyz',
    });

    const chart = sdk.createChart({
      chartId: chartId,
      showAttribution: false,
      filter: {},
      autoRefresh: false,
      maxDataAge: 86400,
    });

    await chart.render(document.getElementById(placementId));
  }, []);

  const handleExport = async (client, date) => {
    setLoadingRow((prev) => ({ ...prev, [date]: true })); // Set loading for the specific row
    try {
      await getDailyUvDefectiveAnomalies(client, date);
      console.log('CSV file downloaded successfully.');
    } catch (error) {
      console.error('Failed to download CSV file:', error);
    } finally {
      setLoadingRow((prev) => ({ ...prev, [date]: false })); // Reset loading state
    }
  };

  const hasData = jsonResponse && jsonResponse.length > 0;

  const xpolDailyAnomalies = [
    ...(jsonResponse?.filter((x) => x.model === 'xpol') || []),
  ];
  const uvDailyAnomalies = [
    ...(jsonResponse?.filter((x) => x.model === 'uv') || []),
  ];

  const fetchResults = async (clientId) => {
    try {
      // setIsLoading(true);
      const response = await getDailyAnomalies(clientId);
      setJsonResponse(response);
    } catch (error) {
      console.error('Error fetching anomaly results:', error);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchCharts = async (clientId) => {
      if (clientId === 'wba' && canSeeSection('wba')) {
        fetchChart('e8e62378-301a-40d0-bb41-d66199beff4e', 'wba_summary_xpol');
        fetchChart('40ba610c-4d08-47d4-a5c7-54e5bd7916ba', 'wba_summary_uv');
      }

      if (clientId === 'sephora_na' && canSeeSection('sephora_na')) {
        fetchChart(
          'd7f1ee2b-6d92-4cf3-8121-77b9212a8624',
          'sephora_na_summary_xpol',
        );
        fetchChart(
          '3ff638b8-26a6-458f-af29-59d331cd18bd',
          'sephora_na_summary_uv',
        );
      }
    };

    if (selectedClient !== null) {
      const clientId = selectedClient.value;
      setJsonResponse(null);
      fetchCharts(clientId);
      fetchResults(clientId);
    }

    // wba
  }, [selectedClient, fetchChart]);

  const renderDataXpol = (client, anomaliesAggregatedInformation) => {
    let rows = [];
    if (
      anomaliesAggregatedInformation &&
      anomaliesAggregatedInformation.length > 0
    ) {
      anomaliesAggregatedInformation.forEach((anomaly) => {
        let outputCells = [];
        outputCells.push({
          value: anomaly.date,
          date: anomaly.date,
        });
        outputCells.push({
          value: anomaly.anomalies?.find((x) => x.anomaly === 'foreign_object')
            ?.count,
          date: anomaly.date,
          propertyName: 'foreign_object',
        });
        outputCells.push({
          value: anomaly.anomalies?.find(
            (x) => x.anomaly === 'incorrect_placement',
          )?.count,
          date: anomaly.date,
          propertyName: 'incorrect_placement',
        });
        outputCells.push({
          value: anomaly.anomalies?.find((x) => x.anomaly === 'out_of_focus')
            ?.count,
          date: anomaly.date,
          propertyName: 'out_of_focus',
        });
        outputCells.push({
          value: anomaly.anomalies?.find((x) => x.anomaly === 'not_skin')
            ?.count,
          date: anomaly.date,
          propertyName: 'not_skin',
        });
        rows.push(outputCells);
      });
    }

    return rows.map((row, index) => (
      <div
        key={index}
        style={{
          display: 'grid',
          gridTemplateColumns: '180px 150px 150px 150px 150px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        {row.map((cell, cellIndex) => (
          <Grid
            key={cellIndex}
            item
            className={classes.cell}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              const { date, propertyName } = cell;
              history.push(
                `/anomalies-detection-day-results?name=${client}&type=daily&model=xpol&date=${date}&propertyName=${propertyName}`,
              );
            }}
          >
            <Paper
              className={cellIndex >= 1 ? classes.paperRight : classes.paper}
            >
              {cell.value || 0}
            </Paper>
          </Grid>
        ))}
      </div>
    ));
  };

  const renderDataUv = (client, anomaliesAggregatedInformation) => {
    let rows = [];
    if (
      anomaliesAggregatedInformation &&
      anomaliesAggregatedInformation.length > 0
    ) {
      anomaliesAggregatedInformation.forEach((anomaly) => {
        let outputCells = [];
        outputCells.push({
          value: anomaly.date,
          date: anomaly.date,
        });
        outputCells.push({
          value: anomaly.anomalies?.find((x) => x.anomaly === 'black')?.count,
          date: anomaly.date,
          propertyName: 'black',
        });
        // outputCells.push({
        //   value: anomaly.anomalies?.find((x) => x.anomaly === 'lift_black')
        //     ?.count,
        //   date: anomaly.date,
        //   propertyName: 'lift_black',
        // });
        // outputCells.push({
        //   value: anomaly.anomalies?.find((x) => x.anomaly === 'lift_off')
        //     ?.count,
        //   date: anomaly.date,
        //   propertyName: 'lift_off',
        // });
        outputCells.push({
          value:
            (anomaly.anomalies?.find((x) => x.anomaly === 'lift_off')?.count ||
              0) +
            (anomaly.anomalies?.find((x) => x.anomaly === 'lift_black')
              ?.count || 0),
          date: anomaly.date,
          propertyName: 'lift_black',
        });
        outputCells.push({
          value: 0,
          date: anomaly.date,
          propertyName: 'export',
        });
        rows.push(outputCells);
      });
    }

    return rows.map((row, index) => (
      <div
        key={index}
        style={{
          display: 'grid',
          gridTemplateColumns: '180px 150px 250px 250px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        {row.map((cell, cellIndex) => (
          <Grid
            key={cellIndex}
            item
            className={classes.cell}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              const { date, propertyName } = cell;
              if (propertyName === 'export') {
                return;
              }
              history.push(
                `/anomalies-detection-day-results?name=${client}&type=daily&model=uv&date=${date}&propertyName=${propertyName}`,
              );
            }}
          >
            {cell.propertyName === 'export' ? (
              <Paper className={classes.paper} style={{ height: '100%' }}>
                <MuiLink
                  component="button"
                  onClick={() => handleExport(client, cell.date)}
                  disabled={loadingRow[cell.date]} // Disable only the clicked link
                  style={{
                    textDecoration: 'none',
                    color: loadingRow[cell.date] ? 'gray' : 'blue',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%', // Stretch to match parent height
                    width: '100%', // Match full width of the Paper
                  }}
                >
                  {loadingRow[cell.date] ? (
                    <>
                      <CircularProgress size={20} color="inherit" />
                      <span style={{ marginLeft: '5px' }}>Loading...</span>
                    </>
                  ) : (
                    'Export UV Defective'
                  )}
                </MuiLink>
              </Paper>
            ) : (
              <Paper
                className={cellIndex >= 1 ? classes.paperRight : classes.paper}
              >
                {cell.value || 0}
              </Paper>
            )}
          </Grid>
        ))}
      </div>
    ));
  };

  const renderHeaderUv = (dates) => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '180px 150px 250px 250px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Date</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>UV Defective</span>
          </Paper>
        </div>
        {/* <div>
          <Paper className={classes.header}>
            <span>Lift black</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Lift off</span>
          </Paper>
        </div> */}
        <div>
          <Paper className={classes.header}>
            <span>Lift off too soon (partial)</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>&nbsp;</span>
          </Paper>
        </div>
      </div>
    );
  };

  const renderHeaderXpol = (dates) => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '180px 150px 150px 150px 150px',
          gridGap: 0,
          backgroundColor: 'transparent',
          textAlign: 'center',
          lineHeight: '20px',
          verticalAlign: 'middle',
        }}
      >
        <div>
          <Paper className={classes.header}>
            <span>Date</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Foreign Object</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Incorrect Placement</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Out of focus</span>
          </Paper>
        </div>
        <div>
          <Paper className={classes.header}>
            <span>Not Skin</span>
          </Paper>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <div>
          <div>
            <Typography className={classes.pageTitle} align="left">
              Daily anomalies
            </Typography>
          </div>
          <FilterByClient onClientChanged={handleClientChanged} />
          {hasData && (
            <div>
              <Typography className={classes.sectionTitle} align="left">
                UV
              </Typography>
            </div>
          )}
          <div>
            {hasData && (
              <Grid item spacing={0}>
                {renderHeaderUv()}
                {selectedClient &&
                  renderDataUv(selectedClient.value, uvDailyAnomalies)}
              </Grid>
            )}
            <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
              {canSeeSection('sephora_na') && (
                <div
                  id="sephora_na_summary_uv"
                  style={{
                    width: '700px',
                    height: '550px',
                    display:
                      selectedClient?.value === 'sephora_na' ? 'block' : 'none',
                  }}
                />
              )}

              {canSeeSection('wba') && (
                <div
                  id="wba_summary_uv"
                  style={{
                    width: '700px',
                    height: '550px',
                    display: selectedClient?.value === 'wba' ? 'block' : 'none',
                  }}
                />
              )}
            </div>
          </div>

          <div>
            {hasData && (
              <Typography className={classes.sectionTitle} align="left">
                XPOL
              </Typography>
            )}
          </div>

          <div>
            {hasData && (
              <Grid item spacing={0}>
                {renderHeaderXpol()}
                {selectedClient &&
                  renderDataXpol(selectedClient.value, xpolDailyAnomalies)}
              </Grid>
            )}

            <div style={{ display: 'flex', marginTop: 30, gap: '20px' }}>
              {canSeeSection('sephora_na') && (
                <div
                  id="sephora_na_summary_xpol"
                  style={{
                    width: '700px',
                    height: '550px',
                    display:
                      selectedClient?.value === 'sephora_na' ? 'block' : 'none',
                  }}
                />
              )}

              {canSeeSection('wba') && (
                <div
                  id="wba_summary_xpol"
                  style={{
                    width: '700px',
                    height: '550px',
                    display: selectedClient?.value === 'wba' ? 'block' : 'none',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
