import {
  AnomaliesDailyReviewScreen,
  AnomaliesMenuScreen,
  AnomaliesMonthlyCountReviewScreen,
  AnomaliesMonthlyZScoreReviewScreen,
  Login,
  StatisticsAppVersionsScreen,
  StatisticsImageUploadsScreen,
  StatisticsMenuScreen,
  StatisticsScansChartsScreen,
  StatisticsScansDataScreen,
  AnomaliesFeedbackScreen,
  DataSetsScreen,
  DataSetViewScreen,
  QatValidationDetailsScreen,
  QatValidationsScreen,
  StoresScreen,
  PortalsMenuScreen,
} from 'views';
import { Config } from 'configs';
import { BaseLayout } from 'components/Layouts/BaseLayout';
import { BlankLayout } from 'components/Layouts/BlankLayout';
import { MLXpolScreen } from 'views/ModelXpolAnomalies';
import { MLUvScreen } from 'views/ModelUvAnomalies';
import { AnomaliesByDateScreen } from 'views/AnomaliesByDate';
import { AnomaliesTopScreen } from 'views/AnomaliesTop';
import { ScannerAnomaliesScreen } from 'views/ScannerAnomalies';
import AnomalousScannersByDay from 'views/AnomalousScannersByDay';
import AnomalousScannersCount from 'views/AnomalousScanners';
import AnomalousScannersZScore from 'views/AnomalousScannersZScore';

export const routes = {
  statistics_menu: {
    path: ['/statistics/menu'],
    component: StatisticsMenuScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  statistics_scans_data: {
    path: ['/statistics/scans/data'],
    component: StatisticsScansDataScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  statistics_scans_chart: {
    path: ['/statistics/scans/charts'],
    component: StatisticsScansChartsScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  statistics_stores_scanners: {
    path: ['/statistics/stores/scanners'],
    component: StoresScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  statistics_app_versions: {
    path: ['/statistics/apps/scanners'],
    component: StatisticsAppVersionsScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  statistics_image_uploads: {
    path: ['/statistics/images/uploads'],
    component: StatisticsImageUploadsScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  portals_menu: {
    path: ['/', '/portals'],
    component: PortalsMenuScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  anomalies_menu: {
    path: ['/anomalies/menu'],
    component: AnomaliesMenuScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  anomalies_daily: {
    path: ['/anomalies/daily'],
    component: AnomaliesDailyReviewScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  anomalies_monthly_count: {
    path: ['/anomalies/monthly/count'],
    component: AnomaliesMonthlyCountReviewScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  anomalies_monthly_zscore: {
    path: ['/anomalies/monthly/zscore'],
    component: AnomaliesMonthlyZScoreReviewScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  anomalies_feedback: {
    path: ['/anomalies/feedback'],
    component: AnomaliesFeedbackScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  anomalies_datasets: {
    path: ['/anomalies/data-sets'],
    component: DataSetsScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  anomalies_datasets_details: {
    path: ['/anomalies/data-sets/:id'],
    component: DataSetViewScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  qat_menu: {
    path: ['/qat'],
    component: QatValidationsScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  qat_details: {
    path: ['/qat/details'],
    component: QatValidationDetailsScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  anomalies: {
    path: ['/jobs/xpol'],
    component: MLXpolScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  uv: {
    path: ['/jobs/uv'],
    component: MLUvScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },

  // AnomalousScannersMenu: {
  //   path: ['/anomalies-detection'],
  //   component: AnomalousScannersMenu,
  //   exact: true,
  //   layout: BaseLayout,
  //   Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  // },
  AnomaliesDetectionResults: {
    path: ['/anomalies-detection-results'],
    component: AnomalousScannersCount,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  AnomaliesDetectionResultsZScore: {
    path: ['/anomalies-detection-results-zscore'],
    component: AnomalousScannersZScore,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  AnomaliesByDayDetectionResults: {
    path: ['/anomalies-detection-day-results'],
    component: AnomalousScannersByDay,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  scannerAnomalies: {
    path: ['/scanner-anomalies'],
    component: ScannerAnomaliesScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  scoresByDate: {
    path: ['/scores-by-date'],
    component: AnomaliesByDateScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  topScores: {
    path: ['/top-scores'],
    component: AnomaliesTopScreen,
    exact: true,
    layout: BaseLayout,
    Roles: [Config.ROLES.AUTHENTICATED, Config.ROLES.ADMIN],
  },
  login: {
    path: '/sign-in',
    component: Login,
    exact: true,
    layout: BlankLayout,
    Roles: ['UNAUTHENTICATED'],
    redirectTo: '/jobs/anomalies',
  },
};

export const AnomaliesMongoDbDashboardLinks = [
  {
    client: 'wba',
    type: {
      anomalies:
        'https://charts.mongodb.com/charts-warehouse-uhqyz/dashboards/5bf75796-480a-40be-b60c-f4ba74fa9ca5',
      uv: 'https://charts.mongodb.com/charts-warehouse-uhqyz/dashboards/9144dfaa-582f-4729-a0d4-c208ce434b53',
    },
  },
  {
    client: 'sephora_na',
    type: {
      anomalies:
        'https://charts.mongodb.com/charts-warehouse-uhqyz/dashboards/6145f433-d9a8-4251-ab39-ef0847258d50',
      uv: 'https://charts.mongodb.com/charts-warehouse-uhqyz/dashboards/b90c3a10-a462-4ed1-bfaa-71d13cd0cba8',
    },
  },
];
