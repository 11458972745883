import React, { useEffect, useMemo, useState } from 'react';
import { getAnomaliesResults } from 'api';
import { useLocation } from 'react-router-dom';
import { FormControl, CircularProgress } from '@mui/material';
import Select from 'react-select';
import ScannerList from './Scanner';
import { makeStyles, Typography } from '@material-ui/core';
import { useClient } from 'hooks/useClient';

const AnomalousScannersZScore = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      //marginLeft: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
      minWidth: 400,
      maxWidth: 400,
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    categoryTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 18,
      fontWeight: 'bold',
    },
    selectInput: {
      //zIndex: 100,
      marginTop: 16,
      marginBottom: 8,
      marginLeft: 10,
      marginRight: 10,
    },
    algorithmExplanation: {
      //zIndex: 100,
      marginLeft: 10,
      marginBottom: 26,
    },
  }));
  const classes = useStyles();

  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const [selectedDateRange, setSelectedDateRange] = useState('');
  const [jsonResponse, setJsonResponse] = useState(null);
  const [dateRanges, setDateRanges] = useState({ weeks: [], months: [] });
  const [isLoading, setIsLoading] = useState(false);
  const algorithm = 'zScore';
  const [canSeeSection] = useClient();

  useEffect(() => {
    const clientName = searchParams.get('name');

    if (!canSeeSection(clientName)) {
      throw new Error("You don't have permission to view this section.");
    }

    const reportType = searchParams.get('type');
    const model = searchParams.get('model');

    const currentYear = new Date().getFullYear();
    const currentWeek = `w${getWeekNumber(new Date())}`;
    const lastMonth = String(new Date().getMonth() + 1).padStart(2, '0');

    const initialDateRange =
      reportType === 'weekly'
        ? `${currentYear}-${currentWeek}`
        : `${currentYear}-${lastMonth}`;
    setSelectedDateRange(initialDateRange);

    generateDateRanges();
    if (clientName && reportType && model) {
      fetchResults(clientName, model, initialDateRange, reportType, algorithm);
    }
  }, [searchParams]);

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  const generateDateRanges = () => {
    const years = [2025, 2024, 2023, 2022];
    let weeks = [];
    let months = [];
    const currentYear = new Date().getFullYear();
    const currentWeek = getWeekNumber(new Date());
    const currentMonth = new Date().getMonth(); // + 1;

    years.forEach((year) => {
      if (year < currentYear) {
        for (let week = 52; week > 0; week--) {
          weeks.push(`${year}-w${week}`);
        }
        for (let month = 12; month > 0; month--) {
          months.push(`${year}-${String(month).padStart(2, '0')}`);
        }
      } else {
        for (let week = currentWeek; week > 0; week--) {
          weeks.push(`${year}-w${week}`);
        }
        for (let month = currentMonth; month > 0; month--) {
          months.push(`${year}-${String(month).padStart(2, '0')}`);
        }
      }
    });
    setDateRanges({ weeks, months });
  };

  const fetchResults = async (
    clientName,
    model,
    dateRange,
    reportType,
    algorithm,
  ) => {
    try {
      setIsLoading(true);
      const response = await getAnomaliesResults(
        clientName,
        model,
        dateRange,
        reportType,
        algorithm,
      );
      setJsonResponse(response);
    } catch (error) {
      console.error('Error fetching anomaly results:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateRangeChange = (selectedItem) => {
    const newDateRange = selectedItem.value;
    setSelectedDateRange(newDateRange);
    const clientName = searchParams.get('name');
    const reportType = searchParams.get('type');
    const model = searchParams.get('model');
    fetchResults(clientName, model, newDateRange, reportType, algorithm);
  };

  const getDateRageOptions = () => {
    return searchParams.get('type') === 'weekly'
      ? dateRanges.weeks.map((week) => ({ value: week, label: week }))
      : dateRanges.months.map((month) => ({ value: month, label: month }));
  };

  return (
    <div>
      <Typography className={classes.pageTitle} align="left">
        Anomalous Scanners (Z-Score, Threshold: {'> 2'})
      </Typography>
      <div className={classes.algorithmExplanation}>
        <span>
          Provides a standardized way to measure how unusual a scanner's
          performance is compared to the average. High Absolute Z-Scores ({'>2'}
          ) indicate significant deviations, flagging potential anomalies.
        </span>
      </div>

      <Typography className={classes.categoryTitle} align="left">
        <strong>Model:</strong> {searchParams.get('model')}
      </Typography>
      <Typography className={classes.categoryTitle} align="left">
        <strong>Client:</strong> {searchParams.get('name')}
      </Typography>

      <Typography className={classes.categoryTitle} align="left">
        <strong>Report Type:</strong> {searchParams.get('type')}
      </Typography>
      <FormControl fullWidth>
        <Select
          // value={selectedDateRange}
          value={getDateRageOptions().find(
            (c) => c.value === selectedDateRange,
          )}
          onChange={handleDateRangeChange}
          className={classes.selectInput}
          options={getDateRageOptions()}
        />
      </FormControl>

      <div>
        {isLoading ? (
          <CircularProgress />
        ) : jsonResponse?.length > 0 ? (
          <>
            <ScannerList
              algorithm={algorithm}
              model={searchParams.get('model')}
              scanners={jsonResponse}
              isLoading={isLoading}
              onScannerClick={(id, propertyNameToSort) => {
                window.open(
                  `/scanner-anomalies?client=${searchParams.get(
                    'name',
                  )}&scanner=${id}&model=${searchParams.get(
                    'model',
                  )}&date_range=${selectedDateRange}&report_type=${searchParams.get(
                    'type',
                  )}&property_name=${propertyNameToSort}`,
                  '_blank',
                );
              }}
            />
          </>
        ) : (
          <div>No data found</div>
        )}
      </div>
    </div>
  );
};

export default AnomalousScannersZScore;
