import { Config } from 'configs';

export const getClients = (includeNone) => {
  const clients = [
    Config.AUTH.SEPHORA_NA,
    Config.AUTH.SEPHORA_SEA,
    Config.AUTH.SEPHORA_EMEA,
    Config.AUTH.SEPHORA_CHINA,
    Config.AUTH.SEPHORA_ME,
    Config.AUTH.WBA,
    Config.AUTH.WBA_US,
    Config.AUTH.WBA_ME,
    Config.AUTH.WBA_NORDIC,
    Config.AUTH.ATOMICA,
    Config.AUTH.JUICE,
    Config.AUTH.JPMS,
    Config.AUTH.SUPERPHARM,
    Config.AUTH.BLUSHBAR,
    Config.AUTH.ULTA,
  ];

  if (includeNone !== undefined && includeNone) {
    clients.unshift({
      ID: -1,
      NAME: 'None',
    });
  }
  return clients;
};

export const clientsList = (clientPrefix) => {
  let clientsList = getClients().map((item) => ({
    value: item.ID,
    label: item.NAME,
  }));

  if (clientPrefix) {
    clientsList = clientsList.filter((item) =>
      item.value.startsWith(clientPrefix),
    );
  }

  return clientsList;
};

export const getCustomersThatRunsML = () => [
  {
    ID: -1,
    NAME: 'None',
  },
  Config.AUTH.SEPHORA_NA,
  Config.AUTH.WBA,
];
