import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useClient } from 'hooks/useClient';
export const AnomaliesMonthlyZScoreReviewScreen = ({ location }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      //marginLeft: theme.spacing(2),
      border: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '10px',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paperLeft: {
      padding: theme.spacing(1),
      textAlign: 'left',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
    },
    paperRight: {
      padding: theme.spacing(1),
      textAlign: 'right',
      paddingLeft: 20,
      color: theme.palette.text.secondary,
      // textDecoration: 'underline',
    },
    pageTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 24,
      fontWeight: 'bold',
    },
    sectionTitle: {
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      fontSize: 20,
      marginTop: 20,
      fontWeight: 'bold',
    },
    warning: {
      color: 'red',
      padding: theme.spacing(1),
      fontSize: 16,
      fontWeight: 'bold',
    },
    header: {
      backgroundColor: 'lightgray',
      color: theme.palette.text.primary,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));
  const classes = useStyles();
  const [canSeeSection] = useClient();

  return (
    <>
      <div>
        <div>
          <Typography
            className={classes.pageTitle}
            style={{ width: '100%', marginTop: 30 }}
            align="left"
          >
            Historical data (ZScore)
          </Typography>
        </div>

        {canSeeSection('sephora_na') && (
          <>
            <div>
              <Typography className={classes.sectionTitle} align="left">
                Sephora NA
              </Typography>
            </div>
            <div style={{ width: '100%', marginTop: 30 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Model</TableCell>
                      <TableCell>Frequency</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        XPOL
                      </TableCell>
                      <TableCell>Monthly</TableCell>
                      <TableCell>
                        <a
                          href={`/anomalies-detection-results-zscore?name=sephora_na&type=monthly&model=xpol`}
                        >
                          View
                        </a>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        UV
                      </TableCell>
                      <TableCell>Monthly</TableCell>
                      <TableCell>
                        <a
                          href={`/anomalies-detection-results-zscore?name=sephora_na&type=monthly&model=uv`}
                        >
                          View
                        </a>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
        {canSeeSection('wba') && (
          <>
            <div>
              <Typography className={classes.sectionTitle} align="left">
                WBA
              </Typography>
            </div>
            <div style={{ width: '100%', marginTop: 30 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Model</TableCell>
                      <TableCell>Frequency</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        XPOL
                      </TableCell>
                      <TableCell>Monthly</TableCell>
                      <TableCell>
                        <a
                          href={`/anomalies-detection-results-zscore?name=wba&type=monthly&model=xpol`}
                        >
                          View
                        </a>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        UV
                      </TableCell>
                      <TableCell>Monthly</TableCell>
                      <TableCell>
                        <a
                          href={`/anomalies-detection-results-zscore?name=wba&type=monthly&model=uv`}
                        >
                          View
                        </a>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
      </div>
    </>
  );
};
