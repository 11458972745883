import { useContext } from 'react';
import { Context as AuthContext } from 'contexts/AuthContext';

export const useClient = () => {
  const { state } = useContext(AuthContext);
  const { user } = state;
  const isClient = user.client_group !== undefined || user.client !== undefined;

  const getClient = () => {
    return user.client || null;
  };

  const getClientGroup = () => {
    return user.client_group || null;
  };
  const getClientGroups = () => {
    return {
      isClientSephoraGroup: user.client_group === 'sephora',
      isClientWbaGroup: user.client_group === 'wba',
    };
  };

  const canSeeSection = (requestedClient) => {
    if (!isClient) return true;

    let clientGroups = getClientGroups();
    let client = getClient();

    if (
      requestedClient === 'sephora_na' &&
      (client === 'sephora_na' || clientGroups.isClientSephoraGroup)
    )
      return true;
    if (
      requestedClient === 'sephora_sea' &&
      (client === 'sephora_sea' || clientGroups.isClientSephoraGroup)
    )
      return true;
    if (
      requestedClient === 'sephora_emea' &&
      (client === 'sephora_emea' || clientGroups.isClientSephoraGroup)
    )
      return true;
    if (
      requestedClient === 'sephora_china' &&
      (client === 'sephora_china' || clientGroups.isClientSephoraGroup)
    )
      return true;
    if (
      requestedClient === 'sephora_me' &&
      (client === 'sephora_me' || clientGroups.isClientSephoraGroup)
    )
      return true;
    if (requestedClient === 'jpms' && client === 'jpms') return true;
    if (requestedClient === 'superpharm' && client === 'superpharm')
      return true;
    if (requestedClient === 'blushbar' && client === 'blushbar') return true;
    if (requestedClient === 'ulta' && client === 'ulta') return true;
    if (
      requestedClient === 'wba' &&
      (client === 'wba' || clientGroups.isClientWbaGroup)
    )
      return true;
    if (
      requestedClient === 'wba_us' &&
      (client === 'wba_us' || clientGroups.isClientWbaGroup)
    )
      return true;
    if (
      requestedClient === 'wba_nordic' &&
      (client === 'wba_nordic' || clientGroups.isClientWbaGroup)
    )
      return true;
    if (
      requestedClient === 'wba_me' &&
      (client === 'wba_me' || clientGroups.isClientWbaGroup)
    )
      return true;
    if (requestedClient === 'abbvie' && client === 'abbvie') return true;
    if (requestedClient === 'chanel' && client === 'chanel') return true;
    return false;
  };

  return [canSeeSection, isClient, getClient, getClientGroup];
};
